import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../components/layout';
import SideBar from '../components/SideBar/SideBar';
import Seo from "../components/seo";

const getdata = graphql`
  {
    wpgraphql {
      page(id: "cG9zdDo4NjU5") {
        id
        title
        uri
        seo {
          canonical
          metaDesc
          metaKeywords
          focuskw
          title
          twitterTitle
          twitterDescription
          opengraphDescription
          opengraphPublishedTime
          opengraphModifiedTime
          opengraphTitle
          opengraphType
          opengraphImage {
            sourceUrl
          }
        }
        caseStudies {
          caseDescription
          caseHeading
          readmore {
            title
            url
            target
          }
          features {
            description
            heading
            readmore {
              target
              title
              url
            }
            logo {
              guid
              title
            }
          }
          caseLogo {
            guid
            title
          }
          caseBanner {
            guid
            title
          }
        }
      }
    }
  }
`
const CaseStudies = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page.caseStudies
  const lastEle = common.features.pop();
  const page = data.wpgraphql.page;
  return (
    <>
      <Layout>
        <Seo title="Our Case Studies" description={page.seo.metaDesc} canonical={page.uri} seo={page.seo} />
        <div class="main-bg">
          <div class="overlay"></div>
          <img src="/assets/images/case-study.jpg" alt="Case Studies" />
          <h1>Case Studies</h1>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div class="Featured">
              <div class="featured-content">
                <h3>Featured Case Studies</h3>
                <img src={common.caseLogo.guid} alt={common.caseLogo.title} />
                <p>{common.caseDescription}</p>
                <a class="Read-More" href={common.readmore.url} target={common.readmore.target}>Read the full case study</a>
              </div>
              <div class="feature-image">
                <img src={common.caseBanner.guid} alt={common.caseBanner.title} />
              </div>
            </div>

            <div class="companies">
              <div class="public-company">
                {common.features.map((cs, index) => {
                  return (
                    <div class="a-company">
                      <h3>{cs.heading}</h3>
                      <img src={cs.logo.guid} alt={cs.logo.title} />
                      <p>{cs.description}</p>
                      <a class="Read-More" href={cs.readmore.url} target="_blank">Read More</a>
                    </div>
                  )
                })}
              </div>
              <div class="carebra">
                <h3>{lastEle.heading}</h3>
                <img src={lastEle.logo.guid} alt={lastEle.logo.title} />
                <p>{lastEle.description}</p>
                <a class="Read-More" href={lastEle.readmore.url} target="_blank">Read More</a>
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ paddingTop: 100 + 'px' }}>
          <SideBar></SideBar>
        </div>
        </div>

      </Layout>
    </>
  )
}
export default CaseStudies
